export async function subscribeEmail(email: string) {
  const res = await fetch('/api/email-opt-in', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email: email }),
  });
  if (!res.ok) throw Error('Something went wrong');
}
