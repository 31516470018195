import { Text, Button, Flex, Container } from 'theme-ui';
import SpinnerBar from '~/components/Spinner/loader';
import { useDashboardContext } from '../Shell';
import { useUser } from '@chordcommerce/gatsby-theme-autonomy';
import { useEffect, useState } from 'react';

import ChevronLeftIcon from '~/assets/images/icons/chevron-left.svg';
import toBase64 from '~/utils/to-base64';
import ProfileForm from './Form';
import Metadata from '~/components/Metadata';
import { unsubscribeEmail } from '~/utils/unsubscribe-email';
import { subscribeEmail } from '~/utils/subscribe-email';

const ProfileDashboard = ({ profile }) => {
  const { goToRoot: goBack } = useDashboardContext();
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [disabled, setDisabled] = useState(true);
  const { user, modifyUser, loadUser } = useUser();

  // Folder for where to store uploads
  const folder = process.env.GATSBY_CLOUDINARY_PROFILE_FOLDER;

  useEffect(() => {
    (async () => {
      try {
        await loadUser();
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);
  const uploadToCloudinary = async (img, name) => {
    if (!img) {
      throw new Error('No Image Provided');
    }
    let signedData = null;
    const sharedBody = { public_id: name, overwrite: true, invalidate: true, folder };
    try {
      // Send over keys and retrieve a signed key for the particular image upload
      signedData = await fetch('/.netlify/functions/signed-cloudinary', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...sharedBody, folder: folder }),
      });
    } catch (error) {
      console.error('SERVER ERROR', error);
      throw new Error(error?.message);
    }

    if (!signedData) {
      throw new Error('Signed data response is missing');
    }

    const { signature, timestamp, apiKey, cloudName } = await signedData.json();

    const body = JSON.stringify({
      file: img,
      api_key: apiKey,
      signature: signature,
      timestamp: timestamp,
      ...sharedBody,
    });

    try {
      // Upload the base64 image up to cloudinary
      const res = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/auto/upload`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body,
      });
      const response = await res.json();
      if (res.ok) {
        return response.url;
      }
      console.error('ERROR UPLOADING', response);
    } catch (e) {
      console.error('ERROR UPLOADING TO CLOUDINARY', e);
    }
    throw new Error(error?.message);
  };

  useEffect(() => {
    if (!success) return;

    setTimeout(() => {
      setSuccess(null);
    }, 5000);
  }, [success]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    setError(null);
    setSuccess(null);

    const entries = [...event.target.elements].map((element) => {
      if (element.type === 'file') return [element.name, element.files[0]];
      if (element.type === 'checkbox') return [element.name, element.checked];
      return [element.name, element.value];
    });

    const { name, email, photo, optIn } = Object.fromEntries(entries);

    const profilePhotoBase64 = photo ? await toBase64(photo) : null;

    let photoURL = null;
    if (photo) {
      try {
        // Retrieve URL from upload
        photoURL = await uploadToCloudinary(profilePhotoBase64, `${profile.data.id}_profile`);
      } catch (e) {
        console.error(e, 'failed to upload');
        setError(e, 'Something went wrong');
        setIsLoading(false);
        return;
      }
    }

    try {
      // 1. Update Chord's OMS
      await modifyUser({
        attributes: {
          name,
          email,
          metadata: {
            ...user.data.metadata,
            photoPath: photoURL ?? user?.data?.metadata?.photoPath,
          },
        },
      });
      // 3. Subscribe or unsubscribe user from email
      if (optIn) await subscribeEmail(email);
      if (!optIn) await unsubscribeEmail(email);
      setSuccess('Updated!');
      setIsLoading(false);
      setDisabled(true);
    } catch (e) {
      setError('Something went wrong');
      setIsLoading(false);
    }
  };

  return (
    <Container
      sx={{
        variant: ['layout.navContainer'],
        py: 0,
        px: [null, 0, 0],
      }}
    >
      <Metadata title="My Profile" />

      <Button variant="bare" onClick={goBack} sx={{ display: [null, 'none'], mb: '3.6rem' }}>
        <ChevronLeftIcon />

        <Text as="span" variant="h5">
          My Profile
        </Text>
      </Button>

      {Object.keys(profile).length > 0 ? (
        <ProfileForm
          handleSubmit={handleSubmit}
          profile={profile}
          isLoading={isLoading}
          success={success}
          error={error}
          disabled={disabled}
          setDisabled={setDisabled}
        />
      ) : (
        <Flex sx={{ justifyContent: 'center', pt: '6.5rem' }}>
          <SpinnerBar startPercent={70} />
        </Flex>
      )}
    </Container>
  );
};

export default ProfileDashboard;
