import { useAuth } from '@chordcommerce/gatsby-theme-autonomy';
import useSWR from 'swr';
import humps from 'humps';

export default function useOrders() {
  const { token } = useAuth();

  const baseUrl = process.env.GATSBY_ACS_API_URL;
  const fetcher = ({ url, token }) =>
    fetch(url, {
      headers: {
        Authorization: token,
      },
    }).then((res) => res.json());

  const { data, isValidating, error, isLoading } = useSWR({ url: `${baseUrl}/api/orders/mine`, token }, fetcher);

  const camelizedData = humps.camelizeKeys(data);

  const orders = camelizedData?.orders?.filter((order) => order.completedAt);

  return {
    orders,
    isValidating,
    isLoading,
    error,
  };
}
