import { Flex, Box, Button } from 'theme-ui';
import SpinnerBar from '~/components/Spinner/loader';
import ProfilePhoto from '~/components/Generic/ProfilePhoto';
import { FormField, FormLabel } from '~/components/Generic/Form';
import { Text } from 'theme-ui';
import { useState, useEffect } from 'react';

import Checkmark from '~/assets/images/icons/check-black.svg';
import clampBuilder from '~/utils/clamp-builder';
import useCheckMembership from '~/hooks/components/use-check-membership';

const ProfileForm = ({ handleSubmit, profile, isLoading, success, error, disabled, setDisabled }) => {
  const [email, setEmail] = useState(profile?.data.email);
  const [name, setName] = useState(profile?.data.name);

  const photo = profile?.data?.metadata?.photoPath;
  const [photoSrc, setPhotoSrc] = useState(photo);
  const [optIn, setOptIn] = useState(null);
  const [photoError, setPhotoError] = useState(null);
  const { isMember, isLoading: isMembershipLoading } = useCheckMembership({ email });
  useEffect(() => {
    if (isMembershipLoading) return;
    setOptIn(isMember);
  }, [isMember, isMembershipLoading]);

  const toggleOptIn = () => {
    setOptIn(!optIn);
    setDisabled(false);
  };

  useEffect(() => {
    if (profile) {
      const {
        data: { email, name },
      } = profile;
      setEmail(email);
      setName(name);
    }
  }, [profile]);

  useEffect(() => {
    if (name !== profile?.data?.name || email !== profile?.data?.email) setDisabled(false);
  }, [name, email]);

  if (!profile)
    return (
      <Flex sx={{ justifyContent: 'center', alignItems: 'center', pt: '4rem' }}>
        <SpinnerBar />
      </Flex>
    );

  return (
    <Flex
      as="form"
      onSubmit={handleSubmit}
      name="profile"
      sx={{
        width: '100%',
        flexFlow: ['column nowrap', 'row nowrap'],
        alignItems: ['center', 'flex-start'],
        fieldset: {
          border: 0,
        },
      }}
    >
      <Box as="fieldset" disabled={isLoading} sx={{ mb: ['3rem', 0], mr: [null, '4.6rem'] }}>
        <ProfilePhoto
          photoSrc={photoSrc}
          setPhotoSrc={setPhotoSrc}
          setDisabled={setDisabled}
          setPhotoError={setPhotoError}
        />
      </Box>

      <Flex
        as="fieldset"
        disabled={isLoading}
        sx={{
          width: ['100%', 'auto'],
          flexFlow: 'column nowrap',
          alignItems: 'stretch',
          flex: [null, '1 1 0'],
        }}
      >
        <FormLabel htmlFor="name">Name</FormLabel>
        <FormField
          type="text"
          name="name"
          defaultValue={name}
          required
          id="name"
          onChange={({ target }) => setName(target.value)}
        />

        <FormLabel htmlFor="email">Email Address</FormLabel>
        <FormField type="email" name="email" defaultValue={email} required disabled id="email" />
        {!isMembershipLoading && email ? (
          <Box
            sx={{
              mt: ['1.5rem'],
              height: ['4.5rem'],
              '.checkbox-container': {
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                cursor: 'pointer',
                webkitUserSelect: 'none',
                mozUserSelect: 'none',
                msUserSelect: 'none',
                userSelect: 'none',
              },
              '.checkbox-container label': {
                width: ['90%', null, '95%'],
                fontSize: ['1.5rem', clampBuilder(768, 1254, 1.4, 1.7), '1.7rem'],
              },
              '.checkbox-container input': {
                position: 'absolute',
                opacity: 0,
                cursor: 'pointer',
                height: 0,
                width: 0,
              },
              '.checkbox-container div': {
                mr: ['1.5rem', '2rem'],
                height: ['25px', null, '30px'],
                width: ['25px', null, '30px'],
                border: '2px solid black',
                borderRadius: '50%',
                position: 'relative',
                transition: 'background-color 0.1s ease',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
              svg: {
                transform: 'scale(0.75)',
                transition: 'opacity 0.1s ease',
              },
              '.checkbox-container input:checked ~ div': {
                backgroundColor: 'cloud',
              },
              '.checkbox-container input ~ div > svg': {
                opacity: 0,
              },
              '.checkbox-container input:checked ~ div > svg': {
                opacity: 1,
              },
            }}
          >
            <Box className="checkbox-container">
              <input type="checkbox" name="optIn" checked={optIn} />
              <Box role="checkbox" aria-checked={optIn} onClick={toggleOptIn}>
                <Checkmark />
              </Box>
              <label htmlFor="optIn">Keep me informed on all updates from OMORPHO</label>
            </Box>
          </Box>
        ) : (
          <Box sx={{ height: '4.5rem', mt: '1.5rem' }} />
        )}
        <Button
          sx={{
            height: '5.4rem',
            mt: ['2.4rem', '3.7rem'],
            ':disabled': { cursor: 'not-allowed', backgroundColor: 'grey' },
          }}
          disabled={disabled}
        >
          {isLoading && (
            <Flex>
              <SpinnerBar color="white" />
            </Flex>
          )}
          {!isLoading && success && <span>Success</span>}
          {!isLoading && !success && <span>Update Profile</span>}
        </Button>
        {!isLoading && error && (
          <Text
            variant="formError"
            sx={{ alignSelf: 'flex-end', width: '100%', maxWidth: '24.4rem', textAlign: 'center', mt: 2 }}
          >
            An unexpected error occurred. Please refresh the page and try again.
          </Text>
        )}
        <Text variant="formError" sx={{ alignSelf: 'flex-end', width: '100%', mt: 2 }}>
          {photoError}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ProfileForm;
