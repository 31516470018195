import { useEffect, useRef } from 'react';
import { Box } from 'theme-ui';
import Metadata from '~/components/Metadata';
import useOrders from '~/hooks/components/useOrders';
import { DashboardBackButton, useDashboardContext } from '../Shell';
import OrdersList from './List';

const OrdersDashboard = ({ location, children }) => {
  const { goToRoot: goBack } = useDashboardContext();
  const { orders, isLoading, error } = useOrders();
  const containerRef = useRef();

  useEffect(() => {
    // By default Reach Router will call `.focus()` into the active route
    // which will cause out mobile view to jump straight into the active route
    // for the "screen-slide-from-left" animation we need to revert that jump
    containerRef.current.scrollTo(0, 0);
  }, [location]);

  return (
    <Box
      sx={{
        width: '100%',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        '> div': {
          display: 'inline-block',

          width: '100%',
          verticalAlign: 'top',
          '> div': {
            minHeight: 'var(--dashboard-height)',
            backgroundColor: 'white',
            height: 'calc(100vh -  var(--dashboard-height))',
            overflowY: 'auto',
            overflowX: 'hidden',
            scrollbarWidth: 'none',
            pr: '10px',
          },
        },
      }}
      ref={containerRef}
    >
      <Metadata title="My Orders" />

      <Box
        sx={{
          height: '100%',
          px: ['var(--horizontal-inset)', 0],
        }}
      >
        <DashboardBackButton onClick={goBack} forwardSx={{ display: [null, 'none'] }}>
          My Orders
        </DashboardBackButton>

        <OrdersList isLoading={isLoading} error={error} orders={orders} />
      </Box>

      {children}
    </Box>
  );
};

export default OrdersDashboard;
