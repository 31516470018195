import { Container, Flex, Grid, Text } from 'theme-ui';
import { Link } from 'gatsby';

import AnimatedButton from '~/components/Generic/AnimatedButton';
import SpinnerBar from '~/components/Spinner/loader';
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy';

const formatter = new Intl.DateTimeFormat('en-US');

const OrdersList = ({ orders, error, isLoading }) => {
  const stripBrand = (orderNum) => orderNum?.split('-')[1];
  const translate = useTranslate();

  if (isLoading || !orders) {
    return (
      <Flex sx={{ minHeight: '100%', justifyContent: 'center', pt: '6.5rem' }}>
        <SpinnerBar />
      </Flex>
    );
  }

  if (error) {
    console.error(error);
    return (
      <Flex sx={{ minHeight: '100%', justifyContent: 'center', pt: '6.5rem' }}>
        <Text as="h3" variant="h4" sx={{ mb: '2.4rem' }}>
          {translate('error.api.default')}
        </Text>
      </Flex>
    );
  }

  if (!orders.length) {
    return (
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Text as="h3" variant="h4" sx={{ mb: '2.4rem' }}>
          You don't have any orders yet
        </Text>
      </Flex>
    );
  }

  return (
    <Container variant="fullwidth">
      {orders.map((order) => (
        <Grid
          as="article"
          sx={{
            gridTemplateColumns: ['1fr 12rem', 'auto 1fr 14rem'],
            gridTemplateAreas: [`"number action" "total action" "details ."`, `"number total action" "details . ."`],
            gridTemplateRows: ['min-content min-content'],
            alignItems: ['center'],
            gap: ['0', '1rem 2.4rem'],
            borderOpacity: 0.3,
            pb: '2.9rem',
            ':not(:first-of-type)': {
              py: '2.9rem',
              borderTop: (theme) => `1px solid ${theme.colors.black}`,
            },
          }}
          key={order.id}
        >
          <Text as="h3" variant="h5" sx={{ gridArea: 'number' }}>
            Order #{stripBrand(order.number)}
          </Text>

          <Text as="p" variant="h5" sx={{ gridArea: 'total', mb: ['0.8rem', 0] }}>
            {order.displayTotal}
          </Text>

          <Text sx={{ gridArea: 'details', fontSize: '1.4rem' }}>
            on {formatter.format(new Date(order.completedAt))} for {order.lineItems.length} ITEMS
          </Text>

          <AnimatedButton
            as={Link}
            to={`/account/orders/${order.id}/`}
            sx={{ variant: 'buttons.primary.small', width: '100% !important', height: '3.6rem' }}
          >
            View
          </AnimatedButton>
        </Grid>
      ))}
    </Container>
  );
};

export default OrdersList;
