import { useEffect, useState } from 'react';
import useProducts from '../graphql/queries/use-products';

export default function useProductsByKitSku(kitSku) {
  const productSkus = kitSku.split('|');
  const products = useProducts();
  const [matches, setMatches] = useState([]);
  useEffect(() => {
    const productMatches = products
      .filter((product) => product.variants.find((variant) => productSkus.includes(variant.sku)))
      .map((product) => {
        const variant = product.variants.find((variant) => productSkus.includes(variant.sku));
        return { ...product, variant };
      });

    setMatches(productMatches);
  }, [kitSku]);
  return matches;
}
