import { graphql } from 'gatsby';

import Layout from '~/components/Layout';
import AccountPage from '~/components/Account/';
import Metadata from '~/components/Metadata';

const Account = ({ data: { page } }) => (
  <Layout transparentNav={page.transparentNav} navColor={page.navColor}>
    <Metadata title={page.title} />
    <AccountPage hero={page.hero} />
  </Layout>
);

export const query = graphql`
  query AccountPageQuery {
    page: contentfulPage(slug: { eq: "account" }) {
      title
      transparentNav
      navColor
      hero {
        ...ContentHeroFragment
        ...CoverFeatureFragment
      }
    }
  }
`;

export default Account;
