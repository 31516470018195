import { Input } from 'theme-ui';
import { Label } from 'theme-ui';
export const FormField = ({ forwardSx = {}, ...props }) => (
  <Input
    sx={{
      width: '100%',
      fontFamily: 'heading',
      fontSize: 1,
      fontWeight: 'medium',
      p: '1.2rem',
      backgroundColor: 'lightBlue',
      borderRadius: '4px',
      border: 0,
      ':not(:last-of-type)': {
        mb: '1.2rem',
      },
      ...forwardSx,
    }}
    {...props}
  />
);

export const FormLabel = ({ children, forwardSx = {}, ...props }) => (
  <Label
    sx={{
      width: '100%',
      lineHeight: '21px',
      fontFamily: 'heading',
      fontSize: '1.4rem',
      fontWeight: 'medium',
      color: 'grey',
      cursor: 'pointer',
    }}
    {...props}
  >
    {children}
  </Label>
);
