import { useEffect, useState } from 'react';
import useSWR from 'swr';

const useCheckMembership = ({ email }: { email: string }) => {
  const [isMember, setIsMember] = useState(false);
  const options = {
    method: 'post',
    body: JSON.stringify({ email }),
  };
  const fetcher = (url: string) => fetch(url, options).then((res) => res.json());
  const { data, isLoading, error } = useSWR('/api/check-membership', fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  if (error) {
    console.error(error);
  }
  useEffect(() => {
    if (isLoading) return;
    if (data === 'Found') {
      setIsMember(true);
    } else {
      setIsMember(false);
    }
  }, [data, isLoading]);

  return { isMember, isLoading };
};

export default useCheckMembership;
