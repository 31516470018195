import { Box, Container, Button, Text } from 'theme-ui';
import { Router } from '@reach/router';
import { createContext, useRef, useEffect, useState, useContext } from 'react';
import { useLocation, navigate } from '@reach/router';

import useMatchMedia from '~/hooks/utils/use-match-media';
import ChevronLeftIcon from '~/assets/images/icons/chevron-left.svg';

export const DashboardNavigation = ({ children }) => {
  const { isHome } = useDashboardContext();

  return (
    <Box
      as="nav"
      sx={{
        position: ['absolute', 'sticky'],
        top: [0, 'calc(var(--nav-height) + 8rem)'],
        left: 0,
        width: [null, 'max-content'],
        height: [null, 'var(--dashboard-height)'],
        pt: ['calc(var(--dashboard-inset-top) + 2px)', 0],
        pr: ['var(--horizontal-inset)', 0, 0],
        pl: ['var(--horizontal-inset)', null, 0],
        '> a, > button': {
          variant: 'text.h5',
          display: 'block',
          fontSize: ['2.6rem', '2rem'],
          opacity: !isHome ? 0.5 : 1,
          ':not(:last-child)': {
            mb: '2.4rem',
          },
        },
      }}
    >
      {children}
    </Box>
  );
};

export const DashboardDetail = ({ children }) => {
  const { isHome } = useDashboardContext();

  return (
    <Box
      as="section"
      sx={{
        position: ['relative', 'static'],
        top: 0,
        right: 0,
        left: [isHome ? '100%' : '0%', 0],
        minHeight: 'var(--dashboard-height)',
        pl: [null, 0, 0],
        pr: [null, null, 0],
        backgroundColor: 'white',
        transition: 'left 0.3s ease-in-out',
        '> div': {
          height: '100%',
        },
      }}
    >
      <Router basepath="/account">{children}</Router>
    </Box>
  );
};

export const DashboardBackButton = ({ children, onClick, forwardSx = {} }) => {
  return (
    <Button
      variant="bare"
      onClick={onClick}
      sx={{ mb: ['3.6rem', '3rem'], 'span, svg': { pointerEvents: 'none' }, ...forwardSx }}
    >
      <ChevronLeftIcon
        sx={{
          width: ['3rem', '1.6rem'],
          height: ['3rem', '1.6rem'],
          mr: '1.8rem',
        }}
      />

      <Text as="span" variant="h5" sx={{ fontSize: ['2.5rem', '1.2rem'] }}>
        {children}
      </Text>
    </Button>
  );
};

const DashboardContext = createContext();
export const useDashboardContext = () => useContext(DashboardContext);

export const DashboardShell = ({ children }) => {
  const containerRef = useRef();
  const isMobileViewport = useMatchMedia('(max-width: 767px)');
  const location = useLocation();
  // In order to be able to transition the unmount of a given active route we'll
  // keep a reference of the pathname that we can compare across renders
  const [activeRoute, setActiveRoute] = useState(location.pathname);

  const isHome = ['/account', '/account/'].includes(activeRoute);

  useEffect(() => {
    // By default Reach Router will call `.focus()` into the active route
    // which will cause out mobile view to jump straight into the active route
    // for the "screen-slide-from-left" animation we need to revert that jump
    if (isMobileViewport) containerRef.current.scrollTo(0, 0);

    setActiveRoute(location.pathname);
  }, [location, isMobileViewport]);

  const goToRoot = () => {
    if (!isMobileViewport) return navigate('/account');

    setActiveRoute('/account');
    setTimeout(() => navigate('/account'), 300);
  };

  return (
    <DashboardContext.Provider value={{ isHome, activeRoute, goToRoot }}>
      <Container
        variant="navContainer"
        sx={{
          '--dashboard-inset-top': ['2.4rem', '8rem'],
          '--dashboard-height': 'calc(100vh - var(--nav-height) - var(--footer-height) - var(--dashboard-inset-top))',
          position: 'relative',
          display: [null, 'grid'],
          gridTemplateColumns: [null, 'auto 1fr'],
          gap: [null, '2.4rem'],
          p: 0,
          pt: 'var(--dashboard-inset-top)',
          maxWidth: '90rem',
          mx: 'auto',
          overflow: ['hidden', 'initial'],
        }}
        ref={containerRef}
      >
        {children}
      </Container>
    </DashboardContext.Provider>
  );
};
