import { Container, Flex } from 'theme-ui';
import SpinnerBar from '~/components/Spinner/loader';

const Home = () => {
  return (
    <Container>
      <Flex sx={{ justifyContent: 'center' }}>
        <SpinnerBar />
      </Flex>
    </Container>
  );
};

export default Home;
