import { Text, Box } from 'theme-ui';
import { Fragment, useEffect, useState } from 'react';
import { Flex } from 'theme-ui';
import { navigate } from 'gatsby';
import SpinnerBar from '~/components/Spinner/loader';
import { DashboardBackButton } from '../Shell';
import useOrders from '~/hooks/components/useOrders';
import formatMoney from '~/utils/format-money';
import LineItemDetail from './LineItemDetail';

const OrderDetail = ({ orderId }) => {
  const [isActive, setIsActive] = useState(false);
  const { orders, isLoading } = useOrders();
  const order = orders?.find((o) => o.id === Number(orderId));

  const handleBack = () => {
    let timeoutId = null;
    setIsActive((prev) => {
      if (!prev) return;

      setIsActive(false);
      timeoutId = setTimeout(() => navigate('../'), 300);
    });
    return () => timeoutId && clearTimeout(timeoutId);
  };

  useEffect(() => {
    const timeout = setTimeout(() => setIsActive(true), 200);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Box
      sx={{
        display: 'inline-block',
        width: '100%',
        verticalAlign: 'top',
        backgroundColor: 'white',
        transform: isActive ? 'translateX(-100%)' : null,
        transition: 'transform 0.2s ease-in-out',
        px: ['var(--horizontal-inset)', 0],
      }}
    >
      <DashboardBackButton onClick={handleBack}>
        <span sx={{ display: [null, 'none'] }}>My Orders</span>
        <span sx={{ display: ['none', 'block'] }}>Back to my orders</span>
      </DashboardBackButton>

      {isLoading && (
        <Flex sx={{ justifyContent: 'center', pt: '2rem' }}>
          <SpinnerBar />
        </Flex>
      )}

      {!isLoading && order && (
        <Fragment>
          <Flex
            sx={{
              flexFlow: 'row nowrap',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: ['1.6rem', '3.4rem'],
            }}
          >
            <Text as="h3" variant="h4" sx={{ lineHeight: '2.4em' }}>
              Order #{order.number?.split('-')[1]}
            </Text>
          </Flex>

          <Box
            as="table"
            sx={{
              width: '100%',
              borderCollapse: 'collapse',
              th: {
                variant: 'text.h5',
                fontSize: '1.1rem',
                color: 'olive',
              },
              td: {
                fontSize: ['1.6rem', 2],
                b: {
                  variant: 'text.h5',
                  fontSize: ['1.6rem', 2],
                },
              },
              thead: {
                textAlign: 'left',
                tr: {
                  borderBottom: (theme) => `1px solid ${theme.colors.olive}`,
                },
                th: {
                  py: '1.6rem',
                },
              },
              tbody: {
                tr: {
                  borderBottom: (theme) => `1px solid ${theme.colors.olive}`,
                },
                td: {
                  verticalAlign: 'top',
                  py: '3rem',
                  ':not(:last-of-type)': { pr: 2 },
                },
              },
              tfoot: {
                th: { pr: 3 },
                'th, td': {
                  verticalAlign: 'middle',
                  py: '0.5rem',
                },
                'tr:first-of-type > *': {
                  pt: '3rem',
                },
                'tr:last-of-type > *': {
                  pb: '3rem',
                  borderBottom: (theme) => `1px solid ${theme.colors.olive}`,
                },
              },
              mb: ['3.6rem', 0],
            }}
          >
            <thead>
              <tr>
                <th scope="column">Product</th>
                <th scope="column">Price</th>
                <th scope="column">QTY</th>
                <th scope="column">Total</th>
              </tr>
            </thead>

            <tbody>
              {order.lineItems.map((item) => (
                <LineItemDetail item={item} key={item.id} />
              ))}
            </tbody>

            <tfoot sx={{ th: { textAlign: 'left' } }}>
              <tr>
                <td colSpan="2"></td>
                <th scope="row">Subtotal</th>
                <td>{order.displayItemTotal}</td>
              </tr>
              {!!Number(order?.adjustmentTotal) && (
                <tr>
                  <td colSpan="2"></td>
                  <th scope="row">Promotions</th>
                  <td>{formatMoney(order.adjustmentTotal, { minimumFractionDigits: 2 })}</td>
                </tr>
              )}
              <tr>
                <td colSpan="2"></td>
                <th scope="row">Tax</th>
                <td>{order.displayTaxTotal}</td>
              </tr>
              <tr>
                <td colSpan="2"></td>
                <th scope="row">Shipping</th>
                <td>{order.displayShipTotal === '$0.00' ? 'FREE' : order.displayShipTotal}</td>
              </tr>
              <tr>
                <td colSpan="2"></td>
                <th scope="row">Total</th>
                <td>
                  <b>{order.displayTotal}</b>
                </td>
              </tr>
            </tfoot>
          </Box>
        </Fragment>
      )}
    </Box>
  );
};

export default OrderDetail;
