import { Text } from 'theme-ui';
import useProductBySku from '~/hooks/components/useProductBySku';
import useProductsByKitSku from '~/hooks/components/useProductsByKitSku';
import { displayOptionValues } from '~/utils/display-option-values';

const LineItemDetail = ({ item }) => {
  const { isBundle } = item;

  const product = useProductBySku(item.variant?.sku);
  const kitProducts = useProductsByKitSku(item?.variant?.sku);

  if ((isBundle && !kitProducts) || (!isBundle && !product)) return null;

  const size = item.variant?.optionValues.find(({ optionTypeName }) => optionTypeName === 'size')?.presentation;
  const color = item.variant?.optionValues.find(({ optionTypeName }) => optionTypeName === 'color')?.presentation;

  const displayKitVariants = () => {
    if (!kitProducts) return null;
    return kitProducts?.map(
      (kitProduct, index, self) => `${kitProduct?.variant?.name}${index === self.length - 1 ? '' : ', '}`
    );
  };

  return (
    <tr data-testid="line-item-detail">
      <td sx={{ display: 'flex', flexFlow: 'row nowrap' }}>
        <span>
          <Text as="span" variant="h5" sx={{ display: 'block', mb: '1.6rem' }}>
            {isBundle ? item.variant.name : product.name}
          </Text>

          <span sx={{ fontSize: 1 }}>
            {isBundle ? displayKitVariants() : displayOptionValues({ size, color }, ', ')}
          </span>
        </span>
      </td>
      <td>{item.singleDisplayAmount.replace('.00', '')}</td>
      <td>{item.quantity}</td>
      <td>
        <b>{item.displayAmount.replace('.00', '')}</b>
      </td>
    </tr>
  );
};

export default LineItemDetail;
