/**
 * Unsubscribe email from marketing list
 * @param {string} email
 */
export async function unsubscribeEmail(email) {
  const res = await fetch('/.netlify/functions/email-opt-out', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email: email }),
  });
  if (!res.ok) throw Error('Something went wrong');
}
