import { Box, Flex, Button, Input, Image } from 'theme-ui';
import VisuallyHidden from '@reach/visually-hidden';
import { useRef } from 'react';

const ProfilePhoto = ({ photoSrc, setPhotoSrc, setDisabled, setPhotoError }) => {
  const handleFileSelection = ({ target }) => {
    const [photo] = target.files;
    const fileSize = photo?.size / 1024 / 1024;
    if (fileSize > 3) {
      setPhotoError('File size exceeds 3MB. Please select a smaller image.');
      setTimeout(() => {
        setPhotoError(null);
      }, 3000);
    } else {
      setPhotoSrc(URL.createObjectURL(photo));
      setDisabled(false);
    }
  };

  const imageRef = useRef();

  const standInAvatar = 'https://upload.wikimedia.org/wikipedia/commons/1/1e/Default-avatar.jpg';

  return (
    <Flex
      sx={{
        position: 'relative',
        flexFlow: 'column nowrap',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          '--photo-size': ['9.3rem', '14.7rem'],
          width: 'var(--photo-size)',
          height: 'var(--photo-size)',
          backgroundColor: 'cloud',
          borderRadius: '50%',
          mb: '2.4rem',
        }}
      >
        <Image
          ref={imageRef}
          src={photoSrc ? photoSrc : standInAvatar}
          alt="profile avatar"
          onError={(e) => {
            console.error('Image Load Error: ', e);
            !!imageRef.current && (imageRef.current.src = standInAvatar);
          }}
          sx={{ width: '100%', height: '100%', borderRadius: '50%', objectFit: 'cover' }}
        />
      </Box>

      <VisuallyHidden>
        <Input type="file" name="photo" onChange={handleFileSelection} id="profile-photo" accept="image/*" />
      </VisuallyHidden>

      <Button as="label" sx={{ variant: 'buttons.inverted' }} htmlFor="profile-photo">
        Edit Picture
      </Button>
    </Flex>
  );
};

export default ProfilePhoto;
