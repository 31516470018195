import { useState, useEffect, useRef } from 'react';
import { Link, navigate } from 'gatsby';
import { Container, Button, Flex } from 'theme-ui';
import { useAuth, useUser } from '@chordcommerce/gatsby-theme-autonomy';
import OrdersDashboard from './Orders/Dashboard';
import ProfileDashboard from './Profile/Dashboard';
import Home from './Home';
import { DashboardDetail, DashboardNavigation, DashboardShell } from './Shell';
import OrderDetail from './Orders/Detail';
import SpinnerBar from '~/components/Spinner/loader';

const AccountPage = ({ hero }) => {
  const logoutRef = useRef();
  const [profile, setProfile] = useState({});
  const { logout, getToken, isLoggedIn, isFetching } = useAuth();
  const { user } = useUser();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (e) {
      console.error(e);
    } finally {
      // Log out if not authenticated
      try {
        await getToken();
      } catch (e) {
        navigate('/login');
      }
    }
  };

  useEffect(() => {
    if (user.data.email) setProfile(user);
  }, [user]);

  useEffect(() => {
    if (!isFetching && !isLoggedIn) navigate('/login');
    if (!isFetching && isLoggedIn) navigate('/account/profile/');
  }, [isFetching, isLoggedIn]);

  if (isFetching) {
    return (
      <Flex sx={{ justifyContent: 'center', alignItems: 'center', pt: '4rem' }}>
        <SpinnerBar />
      </Flex>
    );
  }

  return (
    <Container
      sx={{
        variant: ['layout.navContainer', 'layout.fullWidth', 'layout.fullWidth'],
        maxWidth: [null, 'none'],
        px: [0, 0, 0],
        py: [0, 0, 0],
        backgroundColor: 'white',
        overflow: [null, 'initial'],
      }}
    >
      <DashboardShell>
        <DashboardNavigation>
          <Link to="/account/profile/" partiallyActive activeStyle={{ opacity: 1 }}>
            My Profile
          </Link>

          <Link to="/account/orders/" partiallyActive activeStyle={{ opacity: 1 }}>
            My Orders
          </Link>

          <Button
            ref={logoutRef}
            onClick={handleLogout}
            variant="bare"
            sx={{ variant: 'text.h5', hover: { opacity: 1 }, mt: '-1rem' }}
          >
            Log Out
          </Button>
        </DashboardNavigation>

        <DashboardDetail>
          <OrdersDashboard path="orders">
            <OrderDetail path=":orderId" />
          </OrdersDashboard>

          <ProfileDashboard path="profile" profile={profile} />

          <Home hero={hero} path="/" />
        </DashboardDetail>
      </DashboardShell>
    </Container>
  );
};

export default AccountPage;
